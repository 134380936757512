<template>
    <BaseModal @close="onCloseModal">
        <template v-slot:modalBody>
            <div class="create-modal">
                <div class="create-modal__section create-modal__title">
                    {{title}}
                </div>
                <div class="create-modal__section">
                    <BaseTextInput
                        :value="loginUser"
                        placeholder="Введите логин пользователя"
                        view="primary"
                        @input="onInputLoginUser"
                    />
                </div>
                <div class="create-modal__section">
                    <!-- <BaseSelectMultipleV2
                        :options="tags"
                        :value="tagsSelected"
                        defaultTitle="Выберите теги"
                        class="create-modal__select"
                        @check="onSelectOfferTags"
                    /> -->
                    <TagsCreate
                        :isFullWidth="true"
                        :options="tags"
                        :enteredTags="enteredPointTags"
                        :value="tagsSelected"
                        :isOpenedDropdown="isOpenedTagsRequestDropdown"
                        @check="onSelectOfferTags"
                        @onRemoveTag="onRemoveTag"
                        @onClickCreate="
                            isOpenedTagsRequestDropdown =
                                !isOpenedTagsRequestDropdown
                        "
                    />
                </div>
                <div class="create-modal__section create-modal__actions">
                    <BaseButton
                        class="create-modal__action"
                        @click="onCloseModal"
                    >
                        Отменить
                    </BaseButton>
                    <BaseButton
                        :disabled="!isSendBtn"
                        view="secondary"
                        class="create-modal__action"
                        @click="send"
                    >
                        Отправить
                    </BaseButton>
                </div>
            </div>
        </template>
    </BaseModal>
</template>

<script>
import BaseModal from '@/components/Base/BaseModal.vue';
import BaseTextInput from '@/components/Base/BaseTextInput.vue';
// import BaseSelectMultipleV2 from '@/components/Base/BaseSelectMultipleV2.vue';
import BaseButton from '@/components/Base/BaseButton.vue';
import TagsCreate from "./TagsCreate.vue";

export default {
    name: 'CreateModal',
    components: {
        BaseModal,
        BaseTextInput,
        // BaseSelectMultipleV2,
        BaseButton,
        TagsCreate
    },
    props: {
        title: {
            type: String,
            default: 'Создание предложения'
        },
        tags: {
            type: Array,
            default: () => ([])
        }
    },
    data: () => ({
        tagsSelected: [],
        loginUser: '',
        selectedTag: "",
        enteredPointTags: [],
        isOpenedTagsRequestDropdown: false
    }),
    computed: {
        isSendBtn() {
            return String(this.loginUser) !== '';
        }
    },
    methods: {
        onCloseModal() {
            this.$emit('onCloseModal');
        },
        onSelectOfferTags(value) {
            this.isOpenedTagsRequestDropdown = false;

            if (this.enteredPointTags.includes(value)) return;
            this.selectedTag = value;
            this.enteredPointTags.push(value);
        },
        onRemoveTag(value) {
            this.enteredPointTags = this.enteredPointTags.filter(
                (item) => item !== value
            );
        },
        onInputLoginUser(loginUser = '') {
            this.loginUser = String(loginUser);
        },
        send() {
            this.$emit('send', this.loginUser, this.enteredPointTags);
        }
    }
}
</script>

<style lang="scss" scoped>
    .create-modal {
        &__title {
            font-size: 20px;
            font-weight: 500;
        }

        &__section {
            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }

        &__select {
            width: 100%;

            ::v-deep .select-preview {
                padding: 10px 20px;
            }
        }

        &__actions {
            display: flex;
            justify-content: end;
        }

        &__action {
            &:not(:last-child) {
                margin-right: 5px;
            }
        }
    }
</style>

<template>
    <div class="table-settings">
        <div
            class="table-settings__btn"
            @click="openModal"
        >
            <IconSettings class="table-settings__btn-icon" />
        </div>
        <BaseModal
            v-if="isModal"
            @close="closeModal"
        >
            <template v-slot:modalBody>
                <div class="table-settings__modal">
                    <div class="table-settings__modal-section table-settings__modal-title">
                        Настройки столбцов
                    </div>
                    <div
                        class="table-settings__modal-section table-settings__modal-columns"
                        @drop.prevent="onDropColumn"
                        @dragenter.prevent
                        @dragover.prevent
                    >
                        <div
                            v-for="(column, index) in tableColumnsBuff"
                            :key="index"
                        >
                            <div v-if="isVisibleColumn(column)"
                                :draggable="!getColumnIsSticky(column) && getColumnIsCustomizable(column)"
                                class="table-settings__modal-column"
                                :class="[
                                    'table-settings__modal-column',
                                    { 'table-settings__modal-column_disabled': !getColumnIsCustomizable(column) }
                                ]"
                                @dragstart="onDragStartColumn(column)"
                                @dragover.prevent="onDragOverColumn(column)"
                            >
                                <div class="table-settings__modal-column-checkbox">
                                    <BaseCheckboxV3
                                        :checked="getColumnIsShow(column)"
                                        :size="20"
                                        @change="onChangeIsShow($event, column)"
                                    />
                                </div>
                                <div class="table-settings__modal-column-label">
                                    {{getColumnLabel(column)}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="table-settings__modal-section table-settings__modal-actions">
                        <BaseButton
                            view="border"
                            class="table-settings__modal-action"
                            @click="closeModal"
                        >
                            Отменить
                        </BaseButton>
                        <BaseButton
                            view="secondary"
                            class="table-settings__modal-action"
                            @click="saveTableSettings"
                        >
                            Сохранить
                        </BaseButton>
                    </div>
                </div>
            </template>
        </BaseModal>
    </div>
</template>

<script>
import IconSettings from '../Icons/IconSettings.vue';
import BaseModal from '../Base/BaseModal.vue';
import BaseButton from '../Base/BaseButton.vue';
import BaseCheckboxV3 from '../Base/BaseCheckboxV3.vue';
export default {
    name: 'TableSettings',
    components: {
        IconSettings,
        BaseModal,
        BaseButton,
        BaseCheckboxV3
    },
    props: {
        tableColumns: {
            type: Array,
            default: () => ([])
        },
    },
    data: () => ({
        tableColumnsBuff: [],
        isModal: false,
        columnPropDragged: '',
        columnPropDraggedOver: ''
    }),
    computed: {
        tableColumnsCustomizable() {
            return this.tableColumns.filter(tableColumn => this.getColumnIsCustomizable(tableColumn));
        }
    },
    watch: {
        tableColumnsCustomizable() {
            this.tableColumnsBuff = [...this.tableColumns];
        }
    },
    mounted() {
        this.tableColumnsBuff = [...this.tableColumns];
    },
    methods: {
        openModal() {
            this.isModal = true;
        },

        closeModal() {
            this.isModal = false;
        },

        getColumnProp(column = {}, propKey = '') {
            return column[propKey] ?? null;
        },

        getColumnId(column = {}) {
            return String(this.getColumnProp(column, 'prop') ?? '');
        },

        getColumnIsShow(column = {}) {
            return Boolean(this.getColumnProp(column, 'isShow') ?? false);
        },

        getColumnIsSticky(column = {}) {
            return Boolean(this.getColumnProp(column, 'isSticky') ?? false);
        },

        getColumnIsCustomizable(column = {}) {
            return Boolean(column?.isCustomizable ?? true);
        },

        getColumnLabel(column = {}) {
            return String(this.getColumnProp(column, 'label') ?? '');
        },

        onChangeIsShow(isShow = false, column = {}) {
            const columnIndex = this.tableColumnsBuff.findIndex(columnBuff => String(columnBuff?.prop ?? '') === String(column?.prop ?? ''));
            this.tableColumnsBuff.splice(columnIndex, 1, {...this.tableColumnsBuff[columnIndex], isShow});
        },

        onDragStartColumn(column = {}) {
            console.log('onDragStartColumn');
            this.columnPropDragged = String(column?.prop ?? '');
        },

        onDragOverColumn(column = {}) {
            console.log('onDragOverColumn');
            this.columnPropDraggedOver = String(column?.prop ?? '');
        },

        onDropColumn() {
            console.log('onDropColumn');
            const columnIndexDragged = this.tableColumnsBuff.findIndex(columnBuff => String(columnBuff?.prop ?? '') === String(this.columnPropDragged));
            const columnIndexDraggedOver = this.tableColumnsBuff.findIndex(columnBuff => String(columnBuff?.prop ?? '') === String(this.columnPropDraggedOver));
            const columnDragged = this.tableColumnsBuff[columnIndexDragged] ?? {};
            const columnDraggedOver = this.tableColumnsBuff[columnIndexDraggedOver] ?? {};
            const isSticky = this.getColumnIsSticky(columnDraggedOver);

            if (!isSticky) {
                this.tableColumnsBuff.splice(columnIndexDragged, 1);
                this.tableColumnsBuff.splice(columnIndexDraggedOver, 0, columnDragged);
            }

            this.columnPropDragged = '';
            this.columnPropDraggedOver = '';
        },

        saveTableSettings() {
            this.closeModal();
            this.$emit('onChangeTableColumns', this.tableColumnsBuff);
        },

        isVisibleColumn(column) {
            return !column?.isAction
        }
    }
}
</script>

<style lang="scss" scoped>
    .table-settings {
        &__btn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: 36px;
            border-radius: 4px;
            cursor: pointer;
            transition: all .4s ease-out;

            &-icon {
                width: 20px;
                cursor: pointer;
            }

            &:hover {
                background-color: #f2f2f2;
            }
        }

        &__modal {
            width: 460px;
            &-title {
                font-size: 20px;
                font-weight: 700;
                letter-spacing: 0;
                color: #000;
            }

            &-actions {
                display: flex;
                justify-content: end;
            }

            &-action {
                &:not(:last-child) {
                    margin-right: 5px;
                }
            }

            &-section {
                &:not(:last-child) {
                    margin-bottom: 20px;
                }
            }

            &-columns {
                overflow-y: auto;
                max-height: 300px;
            }

            &-column {
                display: flex;
                align-items: center;
                padding: 10px 15px;
                cursor: grab;
                margin-right: 10px;
                border-radius: 4px;
                user-select: none;
                color: #000;

                &-checkbox {
                    margin-right: 10px;
                }

                &:not(:last-child) {
                    margin-bottom: 10px;
                }

                &:hover {
                    background-color: #f2f2f2;
                }

                &-label {
                    font-size: 12px;
                }

                &_disabled {
                    color: var(--d-color--gray-500);
                    pointer-events: none;
                }
            }
        }
    }
</style>
